<template>
  <v-card>
    <v-card-title>
      <span class="section-title">Status</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-spacer />
        <v-col class="pa-0" justify="center" cols="10" style="text-align:center; float:left;">
          <doughnut-chart class="chart" :chart-data="chartData" :options="chartConfig" />
        </v-col>
        <v-spacer />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash'

export default {
  components: {
    DoughnutChart: () => import('@/components/charts/DoughnutChart.vue')
  },
  props: {
    accounts: Array
  },
  computed: {
    chartLabels() {
      return ['Atualizado', 'Desatualizado', 'Processando', 'Agendado', 'Integrando']
    },
    chartAcc() {
      return this.accounts.reduce(
        (acc, account) =>
          !account?.status_reporter?.status ? acc : { ...acc, [account.status_reporter.status]: (acc[account.status_reporter.status] || 0) + 1 },
        {}
      )
    },
    chartData() {
      return {
        labels: this.chartLabels,
        datasets: [
          {
            data: this.chartLabels.map(label => this.chartAcc[label] || 0),
            backgroundColor: ['#20c997', '#ff6b6b', '#339af0', '#cc5de8', '#f06595'],
            borderWidth: 1,
            datalabels: {
              display: true
            }
          }
        ]
      }
    },
    chartConfig() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true
        },
        legend: {
          labels: {
            usePointStyle: true
          }
        },
        hover: {
          animationDuration: 0
        },
        events: ['click', 'mousemove'],
        responsiveAnimationDuration: 0,
        plugins: {
          datalabels: {
            backgroundColor: 'rgba(248, 249, 250, 0.82)',
            anchor: 'end',
            align: 'start',
            borderRadius: 4,
            color: 'rgba(0, 0, 0, 0.54)',
            formatter: (value, context) => {
              const percent = _.round((value / _.sum(context.dataset.data)) * 100)
              return `${value} - ${percent}%`
            }
          }
        }
      }
    }
  }
}
</script>
